import React from 'react';

const LazyImage = ({ src, alt, id }) => {
  return (
    <div className="grid mt-3 mb-3 justify-items-center md:justify-items-end">
      <img className="md:w-1/3 w-2/3" style={{ aspectRatio: '3 / 2' }} src={src} alt={alt} id={id} />
    </div>
  );
};

export default LazyImage;
